export const CONFIG_VARS = Object.freeze({
  //schoolLoginUrl: "https://poc.inmobiusinfinitylearn.com/login", // prod replicaURL
  //schoolLoginUrl: "https://adminpreprod.inmobiusinfinitylearn.com/login", // pre-prod URL
  //schoolLoginUrl: "https://inmobiusstaging.devinfinitylearn.in/login", // staging URL
  schoolLoginUrl: "https://admin.inmobiusinfinitylearn.com/login", // Prod URL
  baseUrl: {
    //1: "https://adminpreprod.inmobiusinfinitylearn.com/api" // pre-prod URL
    //1: "https://inmobiusstaging.devinfinitylearn.in/api" // staging URL
    1: "https://admin.inmobiusinfinitylearn.com/api" // prod URL
    //1: "https://poc.inmobiusinfinitylearn.com/api" //prod replica
  },
  //phoneBaseUrl: "https://gatewaystaging.devinfinitylearn.in/api", // stating URL
  phoneBaseUrl: "https://gatewayapi.infinitylearn.com/api", // prod URL
  //phoneBaseUrl: "https://gatewayapi.devinfinitylearn.in/api", //prepros
  //phoneBaseUrl: "https://pocgateway.inmobiusinfinitylearn.com/api", //prodreplica

  //otpBaseUrl: "https://otp.devinfinitylearn.in/api", //preprod
  otpBaseUrl: "https://otp.infinitylearn.com/api", //prod

  //scoreViewreportUrl: "https://scorepoc.devinfinitylearn.in/#", // poc
  //scoreViewreportUrl: "https://scorepreprod.devinfinitylearn.in/#", //prepord
  scoreViewreportUrl: "https://scoreservice.infinitylearn.com/#", //pord
  //scoreViewreportUrl: "https://testservice.devinfinitylearn.in/#", //staging

  certficateUrl: "https://certificates-reports.s3.ap-south-1.amazonaws.com/certificate", //prod
  //certficateUrl: " https://certificates-and-reports.s3.ap-south-1.amazonaws.com/certificate", //preprod

  //report_link: "https://inmobiusreports.devinfinitylearn.in/", //prepord
  report_link: "https://inmobiusreports.infinitylearn.com/", //prod

  apiVersion: "v1",
  headers: {
    ["product-id"]: "1",
    ["tenant-id"]: "1"
  },
  router: {
    1: "school",
    2: "user",
    3: "teacher"
  },
  countryApiKey: "a1JiWnJrVVp3YmxheTdybjFtbDVId2xjOU1UYUZ4dUZHMVNBMk5pZA=="
});
