import React from "react";
import Phy from "../../../assets/svg/emsat/Physics.svg";
import Chem from "../../../assets/svg/emsat/Chemistry.svg";
import MAthIcon from "../../../assets/svg/emsat/Mathametics.svg";
import zoo from "../../../assets/svg/emsat/zoo.svg";
import botany from "../../../assets/svg/emsat/botany.svg";
const Subjectwise = ({ reportData }) => {
  const getAvgCorrecttime = (item) => {
    return `${(item?.MARKS_ACHIEVED / item?.TOTAL_MARKS) * 100}%`;
  };

  const getAvgIncorrecttime = (item) => {
    return `${(item?.INCORRECT / item?.TOTAL_MARKS) * 100}%`;
  };

  const getImage = (subject) => {
    switch (subject) {
      case "Physics":
        return Phy;
      case "Chemistry":
        return Chem;
      case "Mathematics":
        return MAthIcon;
      case "Botany":
        return botany;
      case "Zoology":
        return zoo;
      default:
        return null;
    }
  };
  const convertToMinSec = (decimalMinutes) => {
    const minutes = Math.floor(decimalMinutes);
    const seconds = Math.round((decimalMinutes % 1) * 60); // Use modulus for the fractional part
    return `${minutes}m ${seconds}s`;
  };
  return (
    <>
      <div className="EMRep_SubW_cont">
        {reportData?.map((item, index) => (
          <div key={index} className="subjectwise-cont">
            <p className="subjectwise-name">
              <span>
                <img src={getImage(item?.SECTION)} alt={item.name} />
              </span>
              {item?.SECTION}
            </p>
            <p className="subjectwise-Score">
              <span>
                {item?.MARKS_ACHIEVED}/{item?.TOTAL_MARKS}{" "}
              </span>{" "}
              Correct
            </p>
            <div
              className="subjectwise-Progressbar"
              style={{
                backgroundColor: "#D9DBE9",
                borderRadius: "5px",
                overflow: "hidden",
                height: "10px"
              }}
            >
              <div
                style={{
                  width: `${parseFloat(getAvgIncorrecttime(item)) + parseFloat(getAvgCorrecttime(item))}%`,
                  backgroundColor: "#B7295A",
                  height: "100%",
                  position: "relative",
                  borderRadius: "5px"
                }}
              >
                <div
                  style={{
                    width: getAvgCorrecttime(item),
                    backgroundColor: "#00BA88",
                    height: "100%",
                    borderRadius: "5px"
                  }}
                ></div>
              </div>
            </div>
            <p className="subjectwise-Minuts">
              <span>{convertToMinSec(item?.AVG_TIME_TAKEN)}</span> Avg. time/question
            </p>
          </div>
        ))}
      </div>
      {/*m-web*/}
      <div className="EMRep_SubW_contMob">
        {reportData?.map((item, index) => (
          <div key={index} className="subjectwise-contMob">
            <div> {item?.SECTION}</div>
            <div className="subjectwise-inerMob">
              <p className="subjectwise-Score">
                <span>
                  {item?.MARKS_ACHIEVED}/{item?.TOTAL_MARKS}{" "}
                </span>{" "}
                Correct
              </p>
              <div
                className="subjectwise-Progressbar"
                style={{
                  backgroundColor: "#D9DBE9",
                  borderRadius: "5px",
                  overflow: "hidden",
                  height: "10px"
                }}
              >
                <div
                  style={{
                    width: `${parseFloat(getAvgIncorrecttime(item)) + parseFloat(getAvgCorrecttime(item))}%`,
                    backgroundColor: "#B7295A",
                    height: "100%",
                    position: "relative",
                    borderRadius: "5px"
                  }}
                >
                  <div
                    style={{
                      width: getAvgCorrecttime(item),
                      backgroundColor: "#00BA88",
                      height: "100%",
                      borderRadius: "5px"
                    }}
                  ></div>
                </div>
              </div>
              <p className="subjectwise-Minuts">
                <span>{convertToMinSec(item.AVG_TIME_TAKEN)}</span>Avg. time/question
              </p>
            </div>
            {index < reportData?.length - 1 && <div className="subjectwise-vertLineMob"></div>}
          </div>
        ))}
      </div>
    </>
  );
};

export default Subjectwise;
