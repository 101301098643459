import React from "react";
import green from "../../../assets/svg/emsat/greendot.svg";
import red from "../../../assets/svg/emsat/reddot.svg";
import gray from "../../../assets/svg/emsat/graydot.svg";
import line from "../../../assets/svg/emsat/DottedLine.svg";

const ColumnProgressBar = ({ totalQuestions, correctAnswers, incorrectAnswers, unanswered }) => {
  const correctPercentage = (correctAnswers / totalQuestions) * 100;
  const incorrectPercentage = (incorrectAnswers / totalQuestions) * 100;
  const unansweredPercentage = (unanswered / totalQuestions) * 100;
  return (
    <>
      <div className=" EMRep_graph_card">
        <div className="EMRep-column-progress-textCont EMRep-column-progress-textCont2">
          <div className="EMRep-column-2Cont EMRep-column-2Cont1">
            <div>
              <h1>Questions Attempted</h1>
              <p>Total Qs: {totalQuestions}</p>
            </div>

            <div>
              <p className="EMRep-pointer_cont1">
                <span>
                  <img src={green} />
                </span>
                Correct Qs:<span className="EMRep-pointer_Qus">{correctAnswers}</span>
              </p>

              <p className="EMRep-pointer_cont1">
                <span>
                  <img src={red} alt="red" />
                </span>
                Incorrect Qs:<span className="EMRep-pointer_Qus">{incorrectAnswers}</span>
              </p>

              <p className="EMRep-pointer_cont1">
                <span>
                  <img src={gray} alt="gray" />
                </span>
                Unattempted Qs:<span className="EMRep-pointer_Qus">{unanswered}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="EMRep-column-2Cont EMRep-column-progress-textCont2">
          <div className="EMRep-column-progress-barCont">
            {" "}
            <div className="EMRep-column-progress-bar">
              <div
                className="EMRep-unanswered-section"
                style={{ height: `${unansweredPercentage}%` }}
                title={`${unanswered} Unanswered`}
              >
                <div className=" EMRep-lineCont EMRep-lineCont3">
                  {" "}
                  <p className="EMRep-pointer_cont">
                    <span>
                      <img src={gray} />
                    </span>
                    <span className="EMRep-pointer_Qus">{Math.round(unansweredPercentage)}%</span>
                  </p>
                  <img className="EMRep-unAnsline" src={line} />
                </div>
                <div className=" EMRep-lineCont EMRep-lineCont2">
                  {" "}
                  <p className="EMRep-pointer_cont">
                    <span>
                      <img src={red} />
                    </span>
                    <span className="EMRep-pointer_Qus">{Math.round(incorrectPercentage)}%</span>
                  </p>
                  <img style={{ width: "10rem" }} className="EMRep-correctline" src={line} />
                </div>
              </div>
              <div
                className="EMRep-incorrect-section"
                style={{ height: `${incorrectPercentage}%` }}
                title={`${incorrectAnswers} Incorrect`}
              >
                <div className=" EMRep-lineCont">
                  {" "}
                  <p className="EMRep-pointer_cont">
                    <span>
                      <img src={green} />
                    </span>
                    <span className="EMRep-pointer_Qus">{Math.round(correctPercentage)}%</span>
                  </p>
                  <img className="EMRep-correctline" src={line} />
                </div>
              </div>
              <div
                className="EMRep-correct-section"
                style={{ height: `${correctPercentage}%` }}
                title={`${correctAnswers} Correct`}
              ></div>
            </div>
            <div className="EMRep-100">100%</div>
            <div className="EMRep-0">0%</div>
          </div>
        </div>{" "}
      </div>

      <div className=" EMRep_graph_cardMob">
        <div className=" EMRep_graph_HeadMob">
          <h6>Questions Attempted</h6>
        </div>
        <div className="EMRep-column-2Cont EMRep-column-progress-textCont2">
          <div className="EMRep-column-2Cont EMRep-column-progress-textCont2">
            <div className="EMRep-column-progress-barCont">
              {" "}
              <div className="EMRep-column-progress-bar">
                <div
                  className="EMRep-unanswered-section"
                  style={{ height: `${unansweredPercentage}%` }}
                  title={`${unanswered} Unanswered`}
                >
                  <div className=" EMRep-lineCont EMRep-lineCont3">
                    {" "}
                    <p className="EMRep-pointer_cont">
                      <span>
                        <img src={gray} />
                      </span>
                      <span className="EMRep-pointer_Qus">{Math.round(unansweredPercentage)}%</span>
                    </p>
                    <img className="EMRep-unAnsline" src={line} />
                  </div>
                  <div className=" EMRep-lineCont EMRep-lineCont2">
                    {" "}
                    <p className="EMRep-pointer_cont">
                      <span>
                        <img src={red} />
                      </span>
                      <span className="EMRep-pointer_Qus">{Math.round(incorrectPercentage)}%</span>
                    </p>
                    <img style={{ width: "10rem" }} className="EMRep-correctline" src={line} />
                  </div>
                </div>
                <div
                  className="EMRep-incorrect-section"
                  style={{ height: `${incorrectPercentage}%` }}
                  title={`${incorrectAnswers} Incorrect`}
                >
                  <div className=" EMRep-lineCont">
                    {" "}
                    <p className="EMRep-pointer_cont">
                      <span>
                        <img src={green} />
                      </span>
                      <span className="EMRep-pointer_Qus">{Math.round(correctPercentage)}%</span>
                    </p>
                    <img className="EMRep-correctline" src={line} />
                  </div>
                </div>
                <div
                  className="EMRep-correct-section"
                  style={{ height: `${correctPercentage}%` }}
                  title={`${correctAnswers} Correct`}
                ></div>
              </div>
              <div className="EMRep-100">100%</div>
              <div className="EMRep-0">0%</div>
            </div>
          </div>{" "}
        </div>
        <div className="EMRep_graph_PMobCont">
          <p>
            Total Qs:<span>{totalQuestions}</span>
          </p>
          <p>
            <span>
              <img src={green} />
            </span>{" "}
            Correct Qs : {correctAnswers}
            <span></span>
          </p>
          <p>
            <span>
              <img src={red} />
            </span>{" "}
            Inorrect Qs : {incorrectAnswers}
            <span></span>
          </p>
          <p>
            <span>
              <img src={gray} />
            </span>{" "}
            Unattempted Qs : {unanswered}
            <span></span>
          </p>
        </div>
      </div>
    </>
  );
};

export default ColumnProgressBar;
