import React, { useEffect, useState } from "react";
import leftArrow from "../assets/svg/reportspage/LeftArrow.svg";
import back from "../assets/svg/reportspage/leftarow.svg";
import Timer from "../assets/svg/reportspage/gif/timerGIf.gif";
import tick from "../assets/svg/reportspage/gif/tick.gif";
import line from "../assets/svg/reportspage/Line24.svg";
import ProgressBar from "../pages/components/ProgressBar";
import certific from "../assets/svg/reportspage/certificateicon.svg";
import vector from "../assets/svg/reportspage/Vector.svg";
import best from "../assets/svg/reportspage/bestSub.svg";
import imporve from "../assets/svg/reportspage/improveSub.svg";
import CircleProgressBar from "../pages/components/circleProgressBar";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  useExamReportMutation,
  useExamDetailsMutation,
  useEventStatusMutation,
  useAdvanceReportMutation,
  useExamStatusMutation,
  useWebEngageMutation
} from "../store/ApiMutaion";
import loader from "../assets/gif/newLoader.gif";
import AdvanceReport from "./components/AdvanceReport";
import { ROUTES } from "../constants/routes";
import { useSelector } from "react-redux";
import { getSelectedEventsDetails, getToken } from "../store/auth/selectors";
import { format, parseISO } from "date-fns";
import { CONFIG_VARS } from "../constants/configuration";
export const Reports = () => {
  const { examId } = useParams();
  const history = useHistory();

  const [reportData, setReportData] = useState([]);
  const [progressordata, setProgressordata] = useState({});
  const [examDetails, setExamDetails] = useState([]);
  const [condition, setCondition] = useState("timer");
  const [timeLeft, setTimeLeft] = useState(7200);
  const [reportLoader, setReportLoader] = useState(true);
  const [advanceReport, setAdvanceReport] = useState([]);
  const [advanced, setAdvanced] = useState(false);
  const [fetchExamReport] = useExamReportMutation();
  const [fetchExamDetails] = useExamDetailsMutation();
  const [updateStatus] = useEventStatusMutation();
  const [fetchAdvancereport] = useAdvanceReportMutation();
  const [webEngage] = useWebEngageMutation();
  const [formetDate, setFormetData] = useState();
  const [hasFetched, setHasFetched] = useState(false);
  const examdetailes = useSelector(getSelectedEventsDetails);
  const [fetchExamStatus] = useExamStatusMutation();
  const currentExam = examdetailes?.title?.toLowerCase().replace(/[\s\W]+/g, "");
  const handelSendAdvanceReportLSQ = async () => {
    setAdvanced(true);
  };
  const handleBackButtonClick = () => {
    history.push(ROUTES.DASHBOARD);
  };
  const handleFetchExamReport = async (examId, retryCount = 0) => {
    try {
      const data = await fetchExamReport(examId);
      setReportData(data.data);
      setProgressordata(data.data.section_wise_marks);
      setReportLoader(false);
    } catch (error) {
      console.log(`Attempt ${retryCount + 1} failed: ${error.message}`);

      if (retryCount < 3) {
        setTimeout(() => {
          handleFetchExamReport(examId, retryCount + 1);
        }, 1000);
      } else {
        console.log("Max retry attempts reached. Could not fetch the exam report.");
      }
    }
  };

  const handleFetchExamdetials = async (examId) => {
    try {
      const data = await fetchExamDetails(examId);
      setExamDetails(data.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleFetchExamStatus = async (examId) => {
    try {
      let submittedAt;

      if (examdetailes.submitedAt) {
        const utcDate = parseISO(examdetailes.submitedAt);
        submittedAt = new Date(utcDate.getTime() + 5.5 * 60 * 60 * 1000 - 48 * 1000);
      } else {
        submittedAt = parseISO((await fetchExamStatus(examId)).data.submitted_at);
      }
      setFormetData(format(submittedAt, "dd MMMM yyyy"));

      const timeDifference = Math.floor((new Date() - submittedAt) / 1000);
      const initialTimeLeft = Math.max(7200 - timeDifference, 0);
      setTimeLeft(initialTimeLeft);
    } catch (error) {
      console.error("Error fetching exam status:", error.message);
    }
  };

  const handleUpdateExamStatus = async (examId, event_id) => {
    try {
      await updateStatus({ examId: examId, event_id: event_id });
    } catch (error) {
      console.log(error.message);
    }
  };
  const handelAdvancedReport = async (examId, event) => {
    try {
      const data = await fetchAdvancereport({ examId: examId, event: event });
      if (data?.data?.advanced_report.length > 0) {
        DetailedReportWebEngage();
        switch (event) {
          case "score":
            setAdvanceReport(data?.data?.advanced_report[0]);
            setCondition("ready");
            break;
          case "hotsolympiad":
            history.push("/olympaidreports");
            break;
          case "matholympiad":
            history.push("/olympaidreports");
            break;
          case "iemsat":
            history.push("/iemsatreports");
            break;
          default:
            console.warn("Unknown exam title:", examdetailes?.title);
            break;
        }
      } else {
        setCondition("timer");
      }
    } catch (error) {
      console.error("Error fetching report:", error);
      return null;
    }
  };
  useEffect(() => {
    setReportLoader(true);
    handleUpdateExamStatus(examId, examdetailes.event_id);
    handleFetchExamdetials(examId);
    handleFetchExamReport(examId);
    handleFetchExamStatus(examId);
  }, []);
  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(timerId);
    } else if (timeLeft <= 0 && condition === "timer") {
      setCondition("almost");
    }
  }, [timeLeft, condition, advanceReport]);

  useEffect(() => {
    if (timeLeft === 0 && !hasFetched) {
      handelAdvancedReport(examId, currentExam);
      setHasFetched(true);
    }
  }, [timeLeft, examId, currentExam]);

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    const m = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const s = (seconds % 60).toString().padStart(2, "0");
    return `${h}h:${m}m:${s}s`;
  };
  const DetailedReportWebEngage = async () => {
    try {
      await webEngage({
        crn_id: `${examdetailes?.crn_id}`,
        event_name: "Detailed_Report_Generated_Inmobius",
        event_data: [
          { attribute: "test_name", value: `${examdetailes?.StudentExamType + " " + examdetailes?.level}` },
          { attribute: "test_type", value: `${examdetailes?.title}` },
          { attribute: "event_id", value: `${examdetailes?.event_id}` },
          {
            attribute: "CAR_report_basic_link",
            value: `${CONFIG_VARS.report_link}${currentExam}-report/${examdetailes?.crn_id}/${examdetailes?.event_id}`
          },
          { attribute: "Platform", value: "InMobius" },
          { attribute: "page_url", value: `/reports/${examdetailes?.crn_id}/${examId}` }
        ]
      });
    } catch (error) {
      console.error("Error pushing WebEngage event:", error);
    }
  };
  const CertificateWebEngage = async () => {
    try {
      await webEngage({
        crn_id: `${examdetailes?.crn_id}`,
        event_name: "Download_Certificate_Inmobius",
        event_data: [
          { attribute: "test_name", value: `${examdetailes?.StudentExamType + " " + examdetailes?.level}` },
          { attribute: "test_type", value: `${examdetailes?.title}` },
          { attribute: "event_id", value: `${examdetailes?.event_id}` },
          { attribute: "Platform", value: "InMobius" },
          { attribute: "page_url", value: `/reports/${examdetailes?.crn_id}/${examId}` }
        ]
      });
    } catch (error) {
      console.error("Error pushing WebEngage event:", error);
    }
  };

  const ViewSollutionWebEngage = async () => {
    try {
      await webEngage({
        crn_id: `${examdetailes?.crn_id}`,
        event_name: "View_Solution_Inmobius",
        event_data: [
          { attribute: "test_name", value: `${examdetailes?.title + " " + examdetailes?.level}` },
          { attribute: "test_type", value: `${examdetailes?.title}` },
          { attribute: "event_id", value: `${examdetailes?.event_id}` },
          { attribute: "Platform", value: "InMobius" },
          { attribute: "page_url", value: `/reports/${examdetailes?.crn_id}/${examId}` }
        ]
      });
    } catch (error) {
      console.error("Error pushing WebEngage event:", error);
    }
  };

  const handleDownloadCertificet = () => {
    const url = `${CONFIG_VARS.certficateUrl}/${examdetailes.crn_id}/${examdetailes.event_id}.pdf`;
    CertificateWebEngage();
    window.open(url);
  };
  const access_token = useSelector(getToken);
  const handleViewSolutions = async () => {
    await ViewSollutionWebEngage();
    const baseUrl = `${CONFIG_VARS.scoreViewreportUrl}/test/viewsolutionspage?test_type=e34d1d14-6c33-48fd-900e-e8d3cb76da64`;
    const examId = examdetailes.exam_id;
    const qu_id = examdetailes.qu_id;
    const token = access_token.replace("Bearer ", "");
    const url = `${baseUrl}&question_paper_id=${qu_id}&token=${token}&exam_id=${examId}`;
    // window.open(url);
    window.open(url, "_self", "noreferrer");
  };
  return (
    <>
      {!reportLoader ? (
        <div className="Rep_main_cont">
          <div className="Rep_btn-cont">
            <button onClick={handleBackButtonClick} className="Rep_back-btn">
              <span>
                <img src={back} />
              </span>
              back
            </button>
            <div className="Rep_back-btn2">
              <div className="Rep_back-btn2In">
                {" "}
                <img onClick={handleBackButtonClick} src={leftArrow} /> <p>Report</p>
              </div>
            </div>
            <button onClick={handleViewSolutions} className="attempt-btn Rep_attempt-btn1">
              View Solutions
            </button>
          </div>
          <div>
            <h1 className="Rep_exam-title">
              {examdetailes.title} {examdetailes.level} | {formetDate}
            </h1>
            <div className="cetrificate-cont">
              <div className="Rep-text-cont">
                <img src={certific} alt="note" className="mock_img mock_imgweb" />
                <div className="Rep_mock-Cont">
                  <h2 className="mock-text Rep_mock-text">Test Completed</h2>
                  <p className="mock-para Rep_mock-para ">Celebrate your achievement! Download your certificate now.</p>
                </div>
                <img src={certific} alt="note" className="mock_img mock_imgmob" />
              </div>
              <button onClick={handleDownloadCertificet} className="attempt-btn Rep_attempt-btn2">
                Download Certificate
              </button>
            </div>

            <h3 className="Rep_sub-head">Overview</h3>
            <div className="Rep_card-cont">
              <div className="Rep_score_card">
                <img src={vector} alt="vector" className="rep_card_vector" />
                <div>
                  <h4 className="Rep_score_cardHead">Score</h4>
                  <p>
                    {reportData?.marks_obtained}
                    <span>/{reportData?.maximum_marks} marks</span>
                  </p>
                </div>
                <img className="Rep_score_cardLine" src={line} />
                <div className="Rep_score_cardButtom">
                  <div>
                    <h4 className="Rep_score_cardHead">Accuracy</h4>
                    <p className="Rep_score_cardDes">{reportData?.accuracy}%</p>
                  </div>
                  <div>
                    <h4 className="Rep_score_cardHead">Time Taken</h4>
                    <p>{reportData?.time_spent_minutes} M</p>
                  </div>
                  <div>
                    <h4 className="Rep_score_cardHead">Total Time</h4>
                    <p>{examDetails?.duration_in_minutes} M</p>
                  </div>
                </div>
              </div>
              <CircleProgressBar
                totalMarks={reportData?.maximum_marks}
                correctMarks={reportData?.correct_answers}
                incorrectMarks={reportData?.incorrect_answers}
                unattempted={reportData?.unattempted_questions}
              />{" "}
            </div>
            <h3 className="Rep_sub-head">Subject Performance</h3>
            <div className="perform_box">
              <div>
                <div className="perform_webflex">
                  <div>
                    <figure className="mb-0">
                      <img src={best} alt="subone" />
                    </figure>
                  </div>
                  <div className="perform_mobflex">
                    <h3 className="SCRPT-icnhdng no_mb">Best subject</h3>
                    <h4 className="SCRPT-cunt SCRPT-elips">{reportData?.subject_performance?.highest?.section_name}</h4>
                  </div>
                </div>
              </div>
              <div>
                <div className="perform_webflex">
                  <div>
                    <figure className="mb-0">
                      <img src={imporve} alt="subtwo" />
                    </figure>
                  </div>
                  <div className="perform_mobflex">
                    <h3 className="SCRPT-icnhdng no_mb">Scope of improvement</h3>
                    <h4 className="SCRPT-cunt SCRPT-elips">{reportData?.subject_performance?.lowest?.section_name}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="Rep_Sub_cont">
              <h3 className="Rep_graph_cardHead">Sectionwise Score</h3>
              <div className="Rep_Sub_contIn">
                {Object.keys(progressordata).map((subject) => (
                  <div key={subject} className="Rep_Sub_cont-card">
                    <h3 className="Rep_Sub_contSubject">{subject}</h3>
                    <div className="Rep_progressor-cont">
                      <ProgressBar
                        progress={progressordata[subject]?.marks_obtained}
                        total={progressordata[subject]?.total_marks}
                      />
                      <p>
                        {progressordata[subject]?.marks_obtained}/{progressordata[subject]?.total_marks}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            {!advanced ? (
              <div>
                {condition === "timer" && (
                  <div className="Rep_timer-cont">
                    <h1>But Wait!</h1>
                    <p className="Rep_timer-des">
                      We are generating a detailed report for you.
                      <br />
                      <span>You can check back in</span>
                    </p>
                    <p className="Rep_timer-time">{formatTime(timeLeft)}</p>
                    <img src={Timer} alt="Timer" />
                  </div>
                )}
                {condition === "almost" && (
                  <div className="Rep_timer-cont">
                    <h1>Uh-uh!</h1>
                    <p className="Rep_timer-des">
                      Rest assured, your report is in the queue.
                      <br />
                      We will update you once it&apos;s ready
                    </p>
                    <img src={Timer} alt="Timer" />
                  </div>
                )}
                {condition === "ready" && (
                  <div className="Rep_timer-cont">
                    <h1>Your detailed report is ready!</h1>
                    <img src={tick} alt="Tick" />
                    <button onClick={handelSendAdvanceReportLSQ} className="attempt-btn">
                      View Detailed Report
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <AdvanceReport advanceReport={advanceReport} />
            )}
          </div>
        </div>
      ) : (
        <div className="ReportLoaderCont">
          <img className="Reploader" src={loader} />
        </div>
      )}
    </>
  );
};
